<template>
	<div class="line-up">
		<div class="header">
			<div class="top">
				<img class="logo" src="../assets/image/government_icon.png" />
				<div class="title">
					<p class="zh">河北石家庄市长安区政务服务中心</p>
					<p
						class="en"
					>Changan District Government Affairs Service Center, Shijiazhuang City, Hebei Province</p>
				</div>
			</div>
			<div class="bottom">（{{blockName}}）</div>
		</div>
		<div class="body">
			<div class="item">排队叫号信息</div>
			<div class="list">
				<div
					class="item"
					v-for="(item) in list"
					:key="item.id"
					:class="item.status === 2? 'blue':'red'"
				>
					<p v-if="!!item.winNo">请{{item.ticketNoFull}}到{{item.winNo}}号窗口</p>
					<p v-else>{{item.ticketNoFull}}</p>
					<p class="line"></p>
					<p>{{item.status === 2?'排队中':'办理中'}}</p>
				</div>

				<!-- <div class="list-empty"> -->
				<div class="item-empty" v-for="(item,index) in emptyList" :key="index"></div>
				<!-- </div> -->
			</div>
		</div>
	</div>
</template>
<script>
import api from "../api/index";
export default {
	data () {
		return {
			resetScoket: true,
			socket: null,
			macAddr: '',
			param: {

			},
			timer: null,
			list: [],
			emptyList: [],
			clientHeight: 0,
			blockName: '',
			deptId: ''
		}
	},
	mounted () {
		//获取排队列表的高度
		this.clientHeight = `${document.documentElement.clientHeight}` - 432
		console.log(this.clientHeight)
		this.macAddr = localStorage.getItem("macAddr");

		console.log('--', this.deptId + '-')
		this.initWebSocket(this.macAddr)

		this.getDeviceByMacAddr()
    this.getQueueScreenList()
	},
	methods: {
		// 获取设备基础信息
		getDeviceByMacAddr: async function () {
			const res = await api.getDeviceByMacAddr(this.macAddr);
			if (res.code == 200) {
				this.blockName = res.data?.deptName ?? ''
				this.deptId = res.data?.deptId ?? '';
				// this.getQueueScreenList()
			}
		},
		// 获取排队列表
		getQueueScreenList: async function () {
			let param = { macAddr: this.macAddr, deptId: this.deptId, status: '23' };
			const res = await api.getQueueScreenList(this.macAddr);
			this.list = []
			this.emptyList = []
			if (res.rows != null) {
				this.list = [...this.list, ...res.rows]
			}
			//每个item的高度是186 如果list里面有数据，且数据的长度大于排队列表的高度，那么空列表就没有数据
			if (this.list.length * 192 >= this.clientHeight) {
				this.emptyList = []
			} else {//
				let height = this.clientHeight - this.list.length * 192
				let num = Math.ceil(height / 186)
				console.log('-----num', num)
				//如果list里面有数据，但是数据的长度小于排队列表的高度，那么计算出剩余的高度是否大于一个item的高度。如果大于，就向空数组里面放置相应的数据
				if (num >= 1) {
					for (let i = 0; i < num; i++) {
						this.emptyList.push(i)
					}
				} else {
					this.emptyList = []
				}
				console.log('-----emptyList', this.emptyList)
			}
		},

		initWebSocket () {
			this.socket = new WebSocket("ws://caq.etuic.com:8093/eval-api/ws/queue/" + this.macAddr)
			this.socket.onopen = this.socketOnOpen
			this.socket.onmessage = this.socketOnMessage;
			this.socket.onclose = this.socketOnClose;
			// this.socket.onerror = this.socketOnError;

			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
			this.timer = setInterval(() => {
				this.socket?.send(this.macAddr);
			}, 180000)
		},
		socketOnOpen () {
			console.log("连接建立成功");
		},
		socketOnMessage (res) {
			if (/^客户端/.test(res.data) || /^设备/.test(res.data)) {
				return
			}
			let json = JSON.parse(res?.data ?? '{}')
			let code = json.code;
			if (code === 102 || code === 103 || code === 104 || code === 108) {
				this.getQueueScreenList()
			} else if (code === 101) {
				this.$router.replace({
					name: 'DeviceConnect',
					query: {
						refresh: true
					}
				})
			}


		},
		socketOnClose (e) {
			console.log(e)
			// setTimeout(() => {
			// 	if (this.resetScoket) {
			// 		this.initWebSocket();
			// 	}
			// }, 3000)
			if (this.resetScoket) {
				this.initWebSocket();
			}

		},
		socketSendMessgae (mes) {
			let message = JSON.stringify(mes);
			this.socket?.send(message);
		},
	},
	destroyed () {
		this.resetScoket = false;
		this.socket?.close();
		clearInterval(this.timer)
		this.timer = null
	},
}
</script>
<style scoped lang="scss">
.line-up {
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: #d0f4ff;
	.header {
		display: flex;
		flex: 0 0 240px;
		flex-direction: column;
		height: 240px;
		background-color: #282f80;
		.top {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-top: 34px;
			.logo {
				width: 128px;
				height: 128px;
			}
			> .title {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-size: 48px;
				color: #fff;
				margin-left: 26px;
				.en {
					font-size: 20px;
					margin-top: 17px;
				}
			}
		}
		.bottom {
			color: #fff;
			font-size: 29px;
			margin-top: 20px;
			text-align: right;
		}
	}
	.body {
		width: 100%;
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 100%;
		overflow: auto;
		.list {
			height: 100%;
		}

		.item {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			color: #fff;
			height: 182px;
			font-size: 66px;
			margin-top: 10px;
			background-color: #0193f0;
			line-height: 182px;
			text-align: center;
			align-items: center;

			> .line {
				width: 4px;
				height: 35px;
				background-color: #fff;
				margin-left: 50px;
				margin-right: 50px;
			}
		}
		.item-empty {
			width: 100%;
			height: 182px;
			margin-top: 10px;
			background-color: #0193f0;
			line-height: 182px;
		}
		.red {
			background-color: #ff5d5d;
		}
		.blue {
			background-color: #0193f0;
		}
	}
}
@media (orientation: portrait) {
	//竖屏CSS
	.item {
		font-size: 66px;
	}
}
@media (orientation: landscape) {
	//横屏CSS
	.item {
		font-size: 66px;
	}
}
</style>
